import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAuth from '../../hooks/useAuth';
import http from '../../http';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import errorDebug from '../../lib/errorDebug';

const fetchCandidateOptions = (candidateId) =>
  http
    .get(`/candidates/${candidateId}/options`)
    .then(({ data }) => data)
    .catch(errorDebug);

export const useCandidateOptionsQuery = () => {
  const { user } = useAuth();
  return useQuery(['candidates-options'], () =>
    fetchCandidateOptions(user.candidateId),
  );
};

const fetchCandidate = (candidateId) =>
  http
    .get(`/candidates/${candidateId}`)
    .then(({ data }) => data)
    .catch(errorDebug);

export const useFetchCandidateQuery = () => {
  const { user } = useAuth();
  return useQuery(['candidate', user.candidateId], () =>
    fetchCandidate(user.candidateId),
  );
};

const putCandidate = (candidateId, data) =>
  http
    .put(`/candidates/${candidateId}`, data)
    .then(({ data }) => data)
    .catch(errorDebug);

export const usePutCandidateQuery = () => {
  const { user } = useAuth();
  const candidateId = user.candidateId;

  const queryClient = useQueryClient();

  return useMutation(
    ['candidate', candidateId],
    (data) => putCandidate(candidateId, data),
    {
      onSuccess: (newData) =>
        queryClient.setQueryData(['candidate', candidateId], newData),
    },
  );
};

const updateCandidate = (candidateId, data) =>
  http
    .patch(`/candidates/${candidateId}`, data)
    .then(({ data }) => data)
    .catch(errorDebug);

export const useUpdateCandidateQuery = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const candidateId = user.candidateId;

  const queryClient = useQueryClient();

  return useMutation(
    ['candidate', candidateId],
    (data) => updateCandidate(candidateId, data),
    {
      onSuccess: (newData) => {
        toast.info(t('home.account_details.success_note'), {
          toastId: 'update-success',
        });
        return queryClient.setQueryData(['candidate', candidateId], newData);
      },
    },
  );
};

const fetchDocuments = (candidateId) =>
  http
    .get(`/candidates/${candidateId}/documents`)
    .then(({ data }) => data)
    .catch(errorDebug);

export const useDocumentsQuery = () => {
  const { user } = useAuth();
  return useQuery(['candidate-documents', user.candidateId], () =>
    fetchDocuments(user.candidateId),
  );
};

const uploadFile = ({
  candidateId,
  file,
  type,
  options: { options, t, queryClient },
}) => {
  const getDocuments = () =>
    queryClient.getQueryData(['candidate-documents', candidateId]);
  const setDocuments = (mutateFnc) =>
    queryClient.setQueryData(['candidate-documents', candidateId], mutateFnc);

  const totalFileSize = getDocuments().reduce((acc, f) => acc + f.fileSize, 0);
  if (file.size + totalFileSize > options.diskQuota) {
    const diskQuotaInMbs =
      Math.round((options.diskQuota / 1024 / 1024) * 10) / 10;
    return toast.error(
      t('home.errors.size_exceeded', {
        fileName: file.name,
        limit: `${diskQuotaInMbs}MB`,
      }),
    );
  }
  const uploadId = Math.random();
  let newDocument = {
    attachment: file.name,
    originalFilename: file.name,
    contentType: file.type,
    fileSize: file.size,
    uploadProgress: 0,
    uploadId,
    documentType: type,
  };

  if (type === 'CV') {
    setDocuments((prevState) => [
      newDocument,
      ...prevState.filter((f) => f.documentType !== newDocument.documentType),
    ]);
  } else {
    setDocuments((prevState) => [...prevState, newDocument]);
  }
  const formData = new FormData();

  formData.append('files', file);
  formData.append('document_type', type);

  return http
    .post(`/candidates/${candidateId}/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) =>
        setDocuments((prevState) => {
          const index = prevState.findIndex((f) => f.uploadId === uploadId);
          newDocument = {
            ...newDocument,
            uploadProgress: event.progress,
          };
          let prevDocuments = [...prevState];
          prevDocuments.splice(index, 1, newDocument);
          return prevDocuments;
        }),
    })
    .then(({ data }) =>
      setDocuments((prevState) => {
        const index = prevState.findIndex((f) => f.uploadId === uploadId);
        let prevDocuments = [...prevState];
        prevDocuments.splice(index, 1, data[0]);
        return prevDocuments;
      }),
    )
    .catch((err) => {
      errorDebug(err);
      setDocuments((prevState) => {
        const index = prevState.findIndex((f) => f.uploadId === uploadId);
        newDocument = {
          ...newDocument,
          error: true,
        };
        let prevDocuments = [...prevState];
        prevDocuments.splice(index, 1, newDocument);
        return prevDocuments;
      });
    });
};
const addCandidateDocuments = (candidateId, { type, files }, options) =>
  Promise.all(
    files.map((file) => uploadFile({ candidateId, file, type, options })),
  );

export const useAddCandidateDocumentsQuery = () => {
  const { user } = useAuth();
  const candidateId = user.candidateId;

  const queryClient = useQueryClient();
  const { data: options } = useCandidateOptionsQuery();
  const { t } = useTranslation();

  return useMutation(['candidate-documents', candidateId], (data) =>
    addCandidateDocuments(candidateId, data, { queryClient, options, t }),
  );
};

const removeCandidateDocument = (candidateId, document) =>
  http
    .delete(`/candidates/${candidateId}/documents/${document.attachment}`)
    .catch(errorDebug);

export const useRemoveCandidateDocumentQuery = () => {
  const { user } = useAuth();
  const candidateId = user.candidateId;

  const queryClient = useQueryClient();

  return useMutation(
    ['candidate-documents', candidateId],
    (data) => removeCandidateDocument(candidateId, data),
    {
      onSuccess: (_, document) => {
        const documents = queryClient.getQueryData([
          'candidate-documents',
          candidateId,
        ]);
        queryClient.setQueryData(
          ['candidate-documents', candidateId],
          documents.filter((f) => f !== document),
        );
      },
    },
  );
};
