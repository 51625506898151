import * as Yup from 'yup';
import 'yup-phone';
import cx from 'classnames';
import { FormInput, Button } from '@lionstep/ui';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

// Components
import Title from './Title';
import FormPhone from '../../../components/common/formPhone';

// Helpers
import { phoneNumberFormatter } from '../../../lib/string';

// Styles
import classes from './accountDetails.module.scss';

const AccountDetails = ({ className, candidate, onUpdate }) => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    firstName: Yup.string().required(t('rules.required')),
    lastName: Yup.string().required(t('rules.required')),
    phoneNumber: Yup.string()
      .nullable()
      .notRequired()
      .when('phoneNumberCountry', (phoneNumberCountry, schema) => {
        return schema
          .test(
            'not-only-dialCode',
            t('rules.invalid_phone_number'),
            (phoneNumber) => {
              if (!phoneNumber) return true; // ✅ Skip validation if phoneNumber is null
              return phoneNumber !== phoneNumberCountry?.dialCode;
            },
          )
          .phone(
            phoneNumberCountry?.countryCode,
            true,
            t('rules.invalid_phone_number'),
          );
      }),
  });

  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      phoneNumberCountry: {},
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (candidate) {
      reset({
        ...candidate,
        phoneNumber: candidate.phoneNumber
          ? candidate.phoneNumber.replace(/^(?:\+)?0+|[ -]/g, '')
          : '',
      });
    }
  }, [reset, candidate]);

  const handleOnSubmit = (data) => {
    onUpdate({
      ...data,
      phoneNumber: phoneNumberFormatter(data.phoneNumber),
    });
  };

  return (
    <div className={cx(className, classes.accountDetails)}>
      <Title>{t('home.account_details.title')}</Title>

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <div className={classes.formInput}>
            <FormInput
              label={t('home.account_details.first_name')}
              name="firstName"
              error={errors.firstName?.message}
            />
          </div>
          <div className={classes.formInput}>
            <FormInput
              label={t('home.account_details.last_name')}
              name="lastName"
              error={errors.lastName?.message}
            />
          </div>
          <div className={classes.formInput}>
            <FormPhone
              label={t('home.account_details.phone_number')}
              name="phoneNumber"
              error={errors.phoneNumber?.message}
              enableLongNumbers
            />
          </div>
          <div className={classes.formInput}>
            <Button
              className={classes.button}
              minWidth={true}
              ghost={true}
              size="medium"
              typeHtml="submit"
            >
              {t('home.account_details.update')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AccountDetails;
