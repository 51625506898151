import { Button, Filepicker, Text } from '@lionstep/ui';
import DocumentFile from './DocumentFile';
import Title from './Title';

import classes from './documents.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useAddCandidateDocumentsQuery,
  useCandidateOptionsQuery,
  useDocumentsQuery,
  useRemoveCandidateDocumentQuery,
} from '../home.queries';
import useConfirm from '../../../hooks/useConfirm';

const Documents = ({ className }) => {
  const { t } = useTranslation();

  const { data: options } = useCandidateOptionsQuery();

  const { data: documents } = useDocumentsQuery();
  const { mutate: addDocuments } = useAddCandidateDocumentsQuery();
  const { mutate: removeDocument } = useRemoveCandidateDocumentQuery();
  const { confirm } = useConfirm();

  const deleteDocument = (document) => {
    if (document.error) {
      removeDocument(document);
    } else {
      confirm({
        title: t('home.home.delete_title'),
        message: (
          <>
            {t('home.home.delete_message')}
            <br />
            <b>{document.attachment}</b>?
          </>
        ),
        confirmText: t('home.home.delete_confirm_text'),
        confirmButtonType: 'danger',
        cancelText: t('home.home.delete_cancel_text'),
      })
        .then(() => removeDocument(document))
        .catch(() => null);
    }
  };

  return (
    <div className={className}>
      <Title>{t('home.documents.title')}</Title>
      <p className={classes.text}>
        {t('home.documents.description_1')}
        <br />
        {t('home.documents.description_2')}
      </p>

      {!!(documents || []).length && (
        <div className={classes.documentsContainer}>
          {documents.map((document, index) => (
            <DocumentFile
              className={classes.document}
              attachment={document.originalFilename}
              type={
                document.documentType === 'CV'
                  ? 'CV'
                  : t('general.documents_type_other')
              }
              url={`${options?.bucket}${document.attachment}`}
              uploadProgress={document.uploadProgress}
              error={document.error}
              onRemove={() => deleteDocument(document)}
              key={index}
            />
          ))}
        </div>
      )}

      <div className={classes.dropBoxWrapper}>
        <div className={classes.dropBoxItem}>
          <p className={classes.dropBoxLabel}>CV</p>
          <Filepicker
            className={classes.dropBox}
            accept={options?.allowedTypes || {}}
            onDropAccepted={(files) => addDocuments({ type: 'CV', files })}
            maxFiles={1000}
          >
            <p className={classes.dragText}>
              {t('home.documents.drag_and_drop')}
            </p>
            <Button minWidth={false} size="medium" typeHtml="button">
              {t('home.documents.browse_files')}
            </Button>
          </Filepicker>
        </div>
        <div className={classes.dropBoxItem}>
          <p className={classes.dropBoxLabel}>
            {t('home.documents.type_other')}
          </p>
          <Filepicker
            className={`${classes.dropBox}`}
            accept={options?.allowedTypes || {}}
            onDropAccepted={(files) => addDocuments({ type: 'OTHER', files })}
            multiple={true}
            maxFiles={1000}
          >
            <p className={classes.dragText}>
              {t('home.documents.drag_and_drop')}
            </p>
            <Button minWidth={false} size="medium" typeHtml="button">
              {t('home.documents.browse_files')}
            </Button>
          </Filepicker>
        </div>
      </div>
    </div>
  );
};

export default Documents;
