import * as Yup from 'yup';
import AuthenticationSplitter from './components/AuthenticationSplitter';
import LinkedInButton from './components/LinkedInButton';
import classes from './authentication.module.scss';
import { Button, Checkbox, FormInput, Grid, FormCheckbox } from '@lionstep/ui';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

// API
import http from '../../http';

// Libs
import errorDebug from '../../lib/errorDebug';
import { camelToSnakeObj } from '../../lib/common';

// Hooks
import useAuth from '../../hooks/useAuth';
import useLinkedInLogin from '../../hooks/useLinkedInLogin';

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const linkedInLogin = useLinkedInLogin();

  const schema = Yup.object().shape({
    firstName: Yup.string().required(t('rules.required')),
    lastName: Yup.string().required(t('rules.required')),
    email: Yup.string()
      .email(t('rules.email_error'))
      .required(t('rules.required')),
    password: Yup.string()
      .min(6, t('rules.password_error'))
      .required(t('rules.required')),
    agreement: Yup.boolean()
      .required(t('rules.required'))
      .oneOf([true], t('rules.must_check')),
    subscribe: Yup.boolean(),
  });

  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      agreement: false,
      subscribe: false,
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSaveChanges = (data) => {
    http
      .post('/users/candidates/register', camelToSnakeObj(data))
      .then(() => signIn(data))
      .then(() => navigate('/'))
      .catch(errorDebug);
  };

  return (
    <Grid.Container className={classes.gridContainer}>
      <div className={classes.container}>
        <LinkedInButton onClick={linkedInLogin}>
          {t('authentication.sign_up.register_with_linkedin')}
        </LinkedInButton>

        <AuthenticationSplitter />

        <FormProvider {...formMethods}>
          <form
            className={classes.marginTop}
            onSubmit={handleSubmit(onSaveChanges)}
          >
            <FormInput
              label={`${t('authentication.sign_up.first_name')}*`}
              name="firstName"
              placeholder="Jane Doe"
              error={errors.firstName?.message}
            />
            <FormInput
              label={`${t('authentication.sign_up.last_name')}*`}
              name="lastName"
              placeholder="Jane Doe"
              error={errors.lastName?.message}
            />
            <FormInput
              label={`${t('authentication.email')}*`}
              name="email"
              placeholder="janedoe@mail.com"
              error={errors.email?.message}
            />
            <FormInput
              label={`${t('authentication.sign_up.create_password')}*`}
              name="password"
              type="password"
              placeholder="6+ characters"
              error={errors.password?.message}
            />

            <FormCheckbox
              name="agreement"
              options={[
                {
                  label: (
                    <>
                      {t('authentication.sign_up.i_agree_with_lionstep')}
                      <a
                        className={classes.marginLeft}
                        href="https://www.lionstep.com/mainterms/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('authentication.sign_up.privacy_policy')}
                      </a>
                    </>
                  ),
                },
              ]}
              error={errors.agreement?.message}
            />
            <div className={classes.marginTop}>
              <Checkbox
                name="subscribe"
                label={t('authentication.sign_up.i_want_to_subscribe')}
                error={errors.subscribe?.message}
                data-testid="subscribe"
              />
            </div>

            <Button
              className={cx(classes.w100, classes.marginTop)}
              typeHtml="submit"
              data-testid="submit-button"
            >
              {t('authentication.sign_up.register')}
            </Button>
          </form>
        </FormProvider>

        <div className={classes.lastBox}>
          {t('authentication.sign_up.already_have_account')}
          <Link
            className={classes.marginLeft}
            to="/auth/login"
            data-testid="login-link"
          >
            {t('authentication.login')}
          </Link>
        </div>
      </div>
    </Grid.Container>
  );
};

export default SignUp;
