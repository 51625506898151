import * as Yup from 'yup';
import 'yup-phone';
import { Button, FormInput, Grid, Spinner } from '@lionstep/ui';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import cx from 'classnames';

// Queries
import {
  useFetchCandidateQuery,
  usePutCandidateQuery,
} from '../home/home.queries';

// Helpers
import { phoneNumberFormatter } from '../../lib/string';

// Components
import FormPhone from '../../components/common/formPhone';

// Styles
import classes from './authentication.module.scss';

// Constants
const defaultValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneNumberCountry: {},
  linkedinUrl: '',
};

const SignUpStepTwo = () => {
  const { t } = useTranslation();
  const { data: candidate } = useFetchCandidateQuery();
  const { mutate: putCandidate } = usePutCandidateQuery();

  const schema = Yup.object().shape({
    firstName: Yup.string().required(t('rules.required')),
    lastName: Yup.string().required(t('rules.required')),
    phoneNumber: Yup.string()
      .nullable()
      .notRequired()
      .when('phoneNumberCountry', (phoneNumberCountry, schema) => {
        return schema
          .test(
            'not-only-dialCode',
            t('rules.invalid_phone_number'),
            (phoneNumber) => {
              if (!phoneNumber) return true; // ✅ Skip validation if phoneNumber is null
              return phoneNumber !== phoneNumberCountry?.dialCode;
            },
          )
          .phone(
            phoneNumberCountry?.countryCode,
            true,
            t('rules.invalid_phone_number'),
          );
      }),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => {
    if (candidate) {
      const candidateData = Object.keys(defaultValues).reduce(
        (acc, key) => ({ ...acc, [key]: candidate[key] }),
        {},
      );

      reset(candidateData);
    }
  }, [reset, candidate]);

  const handleOnSubmit = (data) => {
    putCandidate({
      ...data,
      phoneNumber: phoneNumberFormatter(data.phoneNumber),
    });
  };

  if (!candidate) return <Spinner position="fixed" size={100} />;

  if (candidate.hasPiModel) return <Navigate to="/" replace />;

  return (
    <>
      <Grid.Container className={classes.gridContainer}>
        <div className={classes.container}>
          <FormProvider {...formMethods}>
            <form
              className={classes.marginTop}
              onSubmit={handleSubmit(handleOnSubmit)}
            >
              <FormInput
                label={`${t('authentication.sign_up_step_two.first_name')}*`}
                name="firstName"
                placeholder="Jane"
                error={errors.firstName?.message}
              />
              <FormInput
                label={`${t('authentication.sign_up_step_two.last_name')}*`}
                name="lastName"
                placeholder="Doe"
                error={errors.lastName?.message}
              />
              <FormPhone
                label={`${t('authentication.sign_up_step_two.phone_number')}*`}
                name="phoneNumber"
                placeholder="0041 78 000 000"
                error={errors.phoneNumber?.message}
              />
              <FormInput
                label={`${t('authentication.sign_up_step_two.linkedin_url')}`}
                name="linkedinUrl"
                placeholder="http://linkedin.com/your-name"
                error={errors.linkedinUrl?.message}
              />

              <Button
                className={cx(classes.w100)}
                typeHtml="submit"
                data-testid="submit-button"
              >
                {t('authentication.sign_up_step_two.save')}
              </Button>
            </form>
          </FormProvider>
        </div>
      </Grid.Container>
    </>
  );
};

export default SignUpStepTwo;
