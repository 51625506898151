import * as Yup from 'yup';
import { Button, FormInput, Grid } from '@lionstep/ui';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

// Api
import http from '../../http';

// Libs
import errorDebug from '../../lib/errorDebug';
import { camelToSnakeObj } from '../../lib/common';

// Styles
import classes from './authentication.module.scss';

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('resetToken');

  const schema = Yup.object().shape({
    password: Yup.string().required(t('rules.required')),
    passwordConfirmation: Yup.string()
      .required(t('rules.required'))
      .oneOf([Yup.ref('password'), null], t('rules.password_must_match')),
  });

  const formMethods = useForm({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSaveChanges = (data) => {
    http
      .patch(`/users/reset-password`, camelToSnakeObj({ ...data, resetToken }))
      .then(() => {
        toast.info(t('authentication.password_recovery.success_note'));
        navigate('/auth/login');
      })
      .catch(errorDebug);
  };

  if (!resetToken) return <Navigate to="/auth/login" replace={true} />;

  return (
    <Grid.Container className={classes.gridContainer}>
      <div className={classes.container}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSaveChanges)}>
            <p className={classes.pleaseMessage}>
              {t('authentication.password_recovery.please_enter_password')}
            </p>
            <div className={classes.marginTop} />
            <FormInput
              name="password"
              type="password"
              label={t('authentication.password')}
              error={errors.password?.message}
            />

            <FormInput
              name="passwordConfirmation"
              type="password"
              label={t('authentication.password_recovery.repeat_new_password')}
              error={errors.passwordConfirmation?.message}
            />

            <Button
              className={classes.w100}
              typeHtml="submit"
              data-testid="submit-button"
            >
              {t('authentication.password_recovery.update_password')}
            </Button>
          </form>
        </FormProvider>
      </div>
    </Grid.Container>
  );
};

export default PasswordRecovery;
