import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '@lionstep/ui';
import { Phone } from '@lionstep/ui-v2';

// Styles
import styles from './formPhone.module.scss';

const FormPhone = ({
  error,
  hint,
  label,
  labelBold,
  name,
  required,
  onChange,
  ...restProps
}) => {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={styles.formItem}>
          {label && (
            <Label
              bold={labelBold}
              hint={hint}
              htmlFor={name}
              required={required}
            >
              {label}
            </Label>
          )}
          <Phone
            name={name}
            error={!!error}
            {...restProps}
            {...field}
            value={field.value || null}
            onChange={(value, country) => {
              setValue(`${name}Country`, country);
              field.onChange(value);

              if (onChange) onChange(value);
            }}
          />

          {error && <p className={styles.formItemError}>{error}</p>}
        </div>
      )}
    />
  );
};

FormPhone.defaultProps = {
  error: '',
  hint: '',
  label: '',
  labelBold: false,
  required: false,
  onChange: null,
};

FormPhone.propTypes = {
  error: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.string,
  labelBold: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FormPhone;
