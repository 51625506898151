import * as Yup from 'yup';
import { Button, FormInput, Grid } from '@lionstep/ui';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { toast } from 'react-toastify';

// Api
import http from '../../http';

// Libs
import errorDebug from '../../lib/errorDebug';

// Styles
import classes from './authentication.module.scss';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('rules.email_error'))
      .required(t('rules.required')),
  });

  const formMethods = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onResetPassword = ({ email }) =>
    http
      .post('/users/reset-password', { email, app: 'CandidatePortal' })
      .then(() => {
        toast.info(t('authentication.forgot_password.success_note'));
        navigate('/auth/login');
      })
      .catch(errorDebug);

  return (
    <Grid.Container className={classes.gridContainer}>
      <div className={classes.container}>
        <p className={classes.pleaseMessage}>
          {t('authentication.forgot_password.please_type_email')}
        </p>

        <FormProvider {...formMethods}>
          <form
            className={classes.marginTop}
            onSubmit={handleSubmit(onResetPassword)}
          >
            <FormInput
              label={`${t('authentication.email')}*`}
              name="email"
              placeholder="janedoe@mail.com"
              error={errors.email?.message}
            />

            <Button
              className={classes.w100}
              typeHtml="submit"
              data-testid="submit-button"
            >
              {t('authentication.forgot_password.reset_password')}
            </Button>
          </form>
        </FormProvider>

        <div className={classes.lastBox}>
          {t('authentication.forgot_password.know_your_password')}
          <Link
            className={classes.marginLeft}
            to="/auth/login"
            data-testid="login-link"
          >
            {t('authentication.forgot_password.login')}
          </Link>
        </div>
      </div>
    </Grid.Container>
  );
};

export default ForgotPassword;
